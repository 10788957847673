
function Banner() {
  return (
    <div class="contain-fluid py-4" >
     <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="images\banner\Sportainment_banner.jpg" alt="Los Angeles" class="img-fluid"  />
    </div>
    </div>
    </div>
  );
}

export default Banner;